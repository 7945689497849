<template>
  <div class="gua-main">
    <div class="content">
      <div v-for="(form, i) in guaList" class="block-item" :key="i">
        <div class="tit">
          <span>担保人({{ i + 1 }})</span>
          <svg
            @click="delItem(i)"
            class="icon"
            viewBox="0 0 1024 1024"
            width="20"
            height="20"
          >
            <path
              d="M202.666667 256h-42.666667a32 32 0 0 1 0-64h704a32 32 0 0 1 0 64H266.666667v565.333333a53.333333 53.333333 0 0 0 53.333333 53.333334h384a53.333333 53.333333 0 0 0 53.333333-53.333334V352a32 32 0 0 1 64 0v469.333333c0 64.8-52.533333 117.333333-117.333333 117.333334H320c-64.8 0-117.333333-52.533333-117.333333-117.333334V256z m224-106.666667a32 32 0 0 1 0-64h170.666666a32 32 0 0 1 0 64H426.666667z m-32 288a32 32 0 0 1 64 0v256a32 32 0 0 1-64 0V437.333333z m170.666666 0a32 32 0 0 1 64 0v256a32 32 0 0 1-64 0V437.333333z"
              fill="#666"
            ></path>
          </svg>
        </div>
        <Form
          :ref="`form-${form.key}`"
          :form-name="`担保人(${i + 1})`"
          :defaultForm="form"
          :fields="fields"
        >
          <van-field
            :slot="`field-custom-name`"
            v-model.trim="form.name"
            label="担保人姓名"
            placeholder="上传识别"
            :rules="required"
            required
            input-align="right"
          >
            <template #right-icon>
              <ImgIcon :size="28" color="#4191f9" @click="openIdcarOcr(i)" />
            </template>
          </van-field>
        </Form>
      </div>
      <div v-if="!guaList.length" class="none-data">暂无担保人</div>
    </div>

    <div class="btns">
      <van-button size="small" @click="addItem">新增</van-button>
      <van-button type="info" size="small" @click="saveFn">保存</van-button>
    </div>
    <IdentityOCR
      v-model="ocrShow"
      :prePlatOrderNo="prePlatOrderNo"
      @success="ocrSuccess"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { guarantorAdd } from "@/api/apply";
export default {
  components: {
    Form: () => import("@/components/Form/Index"),
    ImgIcon: () => import("@/components/ImgIcon.vue"),
    IdentityOCR: () => import("../components/IdentityOCR.vue")
  },
  computed: {
    fields: {
      get() {
        return [
          {
            type: "custom",
            label: "担保人姓名",
            placeholder: "上传识别",
            required: true,
            key: "name",
            validator: (v, pre) => {
              const reg = /^(?:[\u4e00-\u9fa5·]{2,20})$/;
              if (v && !reg.test(v)) throw `${pre}请输入正确的姓名`;
              else return true;
            }
          },
          {
            type: "text",
            label: "担保人身份证号",
            required: true,
            placeholder: "上传识别",
            disabled: true,
            labelWidth: "7em",
            key: "idCardNo",
            validator: (v, pre) => {
              const reg =
                /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/;
              if (v && !reg.test(v)) throw `${pre}请输入正确的身份证号`;
              else return true;
            }
          },
          {
            label: "有效期",
            key: "expiryDate",
            required: true,
            disabled: true,
            placeholder: "上传识别",
            type: "text"
          },
          {
            type: "number",
            label: "担保人手机号",
            required: true,
            labelWidth: "7.2em",
            key: "mobile",
            validator: (v, pre) => {
              const reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
              if (v && !reg.test(v)) throw `${pre}请输入正确的手机号`;
              else return true;
            }
          }
        ];
      },
      set() {
        this.$forceUpdate();
      }
    }
  },
  data() {
    return {
      required: [
        {
          required: true
        }
      ],
      currentIndex: 0,
      guaList: [],
      prePlatOrderNo: "",
      ocrShow: false
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapGetters(["getUserOrderInfo"]),
    init() {
      const data = this.getUserOrderInfo();
      this.prePlatOrderNo = data?.prePlatOrderNo || "";
    },
    openIdcarOcr(index) {
      this.currentIndex = index;
      try {
        const prePlatOrderNo = this.prePlatOrderNo;
        if (!prePlatOrderNo) throw "预进件订单错误";
        this.ocrShow = true;
      } catch (error) {
        this.$errMsg(error);
      }
    },
    ocrSuccess(d) {
      this.$set(this.guaList, this.currentIndex, d);
    },
    addItem() {
      const id = `key-${Math.random().toString(32).slice(-8)}`;
      this.guaList.push({
        key: id,
        name: "",
        idCardNo: "",
        expiryDate: "",
        mobile: ""
      });
    },
    delItem(i) {
      this.guaList.splice(i, 1);
    },
    async saveFn() {
      try {
        for (let i = 0; i < this.guaList.length; i++) {
          const key = this.guaList[i].key;
          const formObj = this.$refs[`form-${key}`][0];
          await formObj.validate();
        }
        const params = {
          prePlatOrderNo: this.prePlatOrderNo,
          guarantorReqVOList: this.guaList
        };
        await guarantorAdd(params);
        this.$toast.success("添加成功");
        const str = this.$route.query.redirect;
        if (str) {
          const redirect = decodeURIComponent(str);
          this.$router.replace(redirect);
        }
      } catch (error) {
        this.$errMsg(error);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.gua-main {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  .content {
    flex: 1;
    overflow-y: auto;
    padding-bottom: 24px;
    padding: 0 24px;
    .block-item {
      border-bottom: 1px solid #dfdfdf;
      .tit {
        line-height: 72px;
        margin-top: 24px;
        font-size: 32px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  .btns {
    height: 112px;
    min-height: 112px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .van-button {
      width: 45%;
      height: 75%;
    }
  }
}
</style>
